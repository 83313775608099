import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import CorporateBusiness from './home/CorporateBusiness';

// Dark Home Layout 
// import DarkMainDemo from './dark/MainDemo';
// import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Resources from "./elements/Resources";
import FAQ from "./elements/FAQ";
import TMSinfo from "./elements/TMSinfo";
import Contact from "./elements/Contact";
import Careers from "./elements/Careers";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import Disclaimer from "./elements/Disclaimer";
import PrivacyPolicy from "./elements/PrivacyPolicy"
import error404 from "./elements/error404";
// import GoogleAdsLanding from "./elements/GoogleAdsLanding";


// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import AllYouNeedToKnowAboutKetamine from "./blocks/blog-posts/AllYouNeedToKnowAboutKetamine";
import KetamineTreatmentTheFutureOfDepressionTreatment from "./blocks/blog-posts/KetamineTreatmentTheFutureOfDepressionTreatment";
import BattlingTheStigmaAroundMentalHealthTreatment from "./blocks/blog-posts/BattlingTheStigmaAroundMentalHealthTreatment";
import LookingAfterYourMentalHealthDuringThePandemic from "./blocks/blog-posts/LookingAfterYourMentalHealthDuringThePandemic";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness}/>


                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/resources`} component={Resources}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={FAQ}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Careers}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/tmsinfo`} component={TMSinfo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/privacypolicy`} component={PrivacyPolicy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/disclaimer`} component={Disclaimer}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/landingpage`} component={GoogleAdsLanding}/> */}


                    {/* Blocks Elements  */}
                    <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/AllYouNeedToKnowAboutKetamine`} component={AllYouNeedToKnowAboutKetamine}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/KetamineTreatmentTheFutureOfDepressionTreatment`} component={KetamineTreatmentTheFutureOfDepressionTreatment}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/BattlingTheStigmaAroundMentalHealthTreatment`} component={BattlingTheStigmaAroundMentalHealthTreatment}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/LookingAfterYourMentalHealthDuringThePandemic`} component={LookingAfterYourMentalHealthDuringThePandemic}/>


                    
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();