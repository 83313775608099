import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMap from "../component/GoogleMaps/GoogleMap"
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";



class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--35" data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title color-1">Contact Us</h2>
                                    <p>Find our Email, Phone #, and Locations below, or send us a message!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h3 className="title">Phone Number</h3>
                                        <p><a href="tel:+057 254 365 456">(480) 576-8666</a></p>
                                    </div>
                                </div>
                                <div className="rn-address" style={{ marginTop: "50px" }}>
                                    <div className="icon" style={{ marginTop: "230px" }}>
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h3 className="title">Email Address</h3>
                                        <p><a href="mailto:tms@rpsych.com">tms@rpsych.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}

                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h3 className="title">Locations</h3>
                                        <h5 className="title" style={{ borderBottom: "dashed" }}>Gilbert</h5>
                                        <p>2730 S Val Vista Drive, Suite 146 <br /> Gilbert, AZ 85295</p>
                                        <br />
                                        <h5 className="title" style={{ borderBottom: "dashed" }}>Chandler</h5>
                                        <p>600 S Dobson Road, Suite 36-E <br /> Chandler, AZ 85224</p>
                                        <br />
                                        <h5 className="title" style={{ borderBottom: "dashed" }}>Phoenix</h5>
                                        <p>12424 North 32nd Street, Suite 101 <br /> Phoenix, AZ 85032</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMap />
                    </div>
                </div>
                <br /><br /><br /><br /><br />
                {/* End Contact Map  */}


                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Contact