import React, { Component } from "react";
import ReactTextCollapse from "react-text-collapse";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
    expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
    minHeight: 0, // component height when closed
    maxHeight: 200, // expanded to
    color: "black",
    textStyle: { // pass the css for the collapseText and expandText here
        color: "#003C8F",
        fontSize: "20px",
        marginLeft: "33%",
    }
}
const TEXT_COLLAPSE_OPTIONS2 = {
    collapse: false, // default state when component rendered
    collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Symptoms of Depression</h6><FiChevronDown /></div>, // text to show when collapsed
    expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Hide Symptoms of Depression </h6><FiChevronUp /></div>, // text to show when expanded
    minHeight: 0, // component height when closed
    maxHeight: 500, // expanded to
    color: "black",
    textStyle: { // pass the css for the collapseText and expandText here
        color: "#003C8F",
        fontSize: "20px",
        marginLeft: "22%",
    }
}
const TEXT_COLLAPSE_OPTIONS3 = {
    collapse: false, // default state when component rendered
    collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
    expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
    minHeight: 0, // component height when closed
    maxHeight: 300, // expanded to
    color: "black",
    textStyle: { // pass the css for the collapseText and expandText here
        color: "#003C8F",
        fontSize: "20px",
        marginLeft: "33%",
    }
}
const TEXT_COLLAPSE_OPTIONS4 = {
    collapse: false, // default state when component rendered
    collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
    expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
    minHeight: 0, // component height when closed
    maxHeight: 350, // expanded to
    color: "black",
    textStyle: { // pass the css for the collapseText and expandText here
        color: "#003C8F",
        fontSize: "20px",
        marginLeft: "33%",
    }
}

class TMSinfo extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title color-1">Transcranial Magnetic Stimulation (TMS)</h2>
                                    <p>Noninvasive, innovating, non-drug approach treatment for depression</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{textAlign:"center", fontStyle: "bold", fontSize:"2rem"}}>What is TMS?</h3>
                                                    <p>Transcranial Magnetic Stimulation (TMS) is a noninvasive, well-tolerated procedure that uses pulsed magnetic fields to stimulate nerve cells in the brain to condition long-lasting changes in dysfunctional neural pathways without the systemic side effects that often occur with medications. The magnetic field is created by a special coil that focuses the magnetic energy at very specific areas of the brain, such as the pre-frontal cortex, which is the primary cortical area involved in emotional regulation. The course of treatment involves multiple sessions over time, which is also known as repetitive TMS (rTMS)
                                                    <br /><br />
                                                    <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                                                            For depression, this usually consists of 20-minute sessions 5 days a week for 6 weeks. TMS patients often notice a significant reduction in symptoms and experience improvement in their quality of life, reduction in medication use, fewer doctor’s visits, and reduced risk of hospitalizations and suicide.
                                                    </ReactTextCollapse></p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <br /><br /><br />

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{textAlign:"center", fontStyle: "bold", fontSize:"2rem"}}>What is major depression?</h3>
                                                    <p>Major depression is a debilitating brain disorder that affects one in ten adults. It is caused by an imbalance of chemicals in the brain that result in a persistent state of sadness and loss of interest or pleasure from normal activities. Brain imaging studies show that in patients with major depression there is a lack of activity in the prefrontal cortex of the brain, which is the primary emotional center of the cortex.</p><br /><br />
                                                    <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS2}>
                                                        <h6>Symptoms of depression may include:</h6>
                                                        <ul className="liststyle">
                                                            <li>Feeling sad, hopeless, or depressed</li>
                                                            <li>Difficulty concentrating, thinking, or making decisions</li>
                                                            <li>Loss of interest or pleasure in activities once enjoyed</li>
                                                            <li>Changes in appetite, increased or decreased</li>
                                                            <li>Lack of energy or easily tired</li>
                                                            <li>Trouble sleeping or sleeping too much</li>
                                                            <li>Excessive feelings of guilt or worthlessness</li>
                                                            <li>Restlessness or sluggishness</li>
                                                            <li>Trouble concentrating, remembering details, and making decisions</li>
                                                            <li>Digestive problems that don't get better, even with treatment</li>
                                                            <li>Thoughts of death or suicide</li>
                                                        </ul>
                                                    </ReactTextCollapse>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <br /><br /><br /><br /><br />

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-03.png" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{textAlign:"center", fontStyle: "bold", fontSize:"2rem"}}>The Difference Between TMS and Antidepressants</h3>
                                                    <p>TMS treatment is magnetic energy and not a chemical so it does not circulate in the blood stream. It does not produce systemic (whole body) side effects such as nausea, weight gain, sexual dysfunction, sedation, or suicidal thoughts that can be associated with antidepressants. The most common side effects of TMS were headache and mild scalp discomfort during treatment, which becomes less intense with subsequent treatments.
                                                    <br /><br />
                                                    <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS3}>
                                                            Overall, TMS is well tolerated meaning that it is very uncommon that side effects are severe enough to cause a patient to discontinue TMS treatment. TMS can be used in conjunction with medication and therapy and is an effective complementary treatment. Because TMS does not produce changes outside the treatment area in the brain, it does not cause systemic side effects, and consequently, it is safe for women who are pregnant or breastfeeding.
                                                    </ReactTextCollapse></p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <br /><br /><br />

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3 style={{textAlign:"center", fontStyle: "bold", fontSize:"2rem"}}>How does TMS work?</h3>
                                                    <p>TMS can condition long-term changes in specific, targeted areas of the brain. In depression, a specific area of the frontal lobe of the brain, known as the dorsolateral pre-frontal cortex, is underactive. TMS stimulation causes the neurons in the brain to fire in a specific pulse pattern that can either excite or inhibit brain circuits.</p>
                                                    <br />
                                                    <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS4}>
                                                            <p>For excitatory stimulation, increased firing has been shown to trigger the release of Brain Derived Neurotrophic Growth Factor (BDNF) and increase electrical activity in the areas stimulated. This in turn causes excitation in related brain regions connected to the area stimulated. Research has shown increased blood flow, oxygen use, and glucose metabolism at the stimulated areas as well as increased neuronal connections and cellular growth. In this way, TMS is a lot like rehab or exercise for the brain, but instead of getting bigger, stronger muscles, you get active, well-connected neurons.</p>
                                                    </ReactTextCollapse>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-04.png" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default TMSinfo;