import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class Accordion01 extends Component {
    render() {
        return (
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What is TMS Therapy?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Transcranial Magnetic Stimulation, or TMS, is a non-invasive procedure used in treatment resistant depression. It works by stimulating neurons in the brain using an electromagnetic field, similar to that used with an MRI. Short pulses of this magnetic field are delivered in a rapid sequence to the area of the brain which houses depression. This stimulation increases blood flow to the area and overtime structural changes occur.  </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What are the benefits and/or risks of TMS?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            TMS is completely non-invasive. Anesthesia, nor sedation of any type is required. Therefore, patients can drive themselves home after treatment and resume all normal activities. This is a stark comparison to other treatments, such as ECT, which requires being checked into a hospital, receiving local anesthesia, and requiring care for several days following treatment, as it often causes confusion and memory loss.
                            <br /><br />
                            TMS has very few side effects associated with it, which is one of the many reasons it comes highly recommended by past patients. Some symptoms reported by TMS patients include a slight headache, or tenderness of the scalp. This is something that usually alleviates within the first week or so of treatment. Headaches can be treated with typical over the counter pain relievers. If those do not help, or headaches persist past the first week of treatment, adjustments can be made to the strength of the magnetic pulse being delivered, or the treatment location.
                            <br /><br />
                            TMS is also an extremely effective treatment, especially when compared to traditional treatments for depression, like antidepressant medications. Antidepressants have a broad side effect profile, with sometimes severe and life altering symptoms, and often very little improvement. When looking at studies overall, two thirds of TMS patients will experience a reduction of depressive symptoms, and a third of those patients will have complete remission of symptoms.
                            <br /><br />
                            Because TMS does not produce changes outside the treatment area in the brain, it does not cause systemic side effects, and consequently, it is even safe for women who are pregnant or breastfeeding.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Does insurance cover TMS?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            TMS is generally covered by insurance for treatment-resistant depression when patients have met the pre-requisites for prior authorization. These requirements are set by each induvial company, and their approval is only required when seeking their reimbursement for treatment. If approval is received by an insurance company for a patient to receive TMS, the insurance company is billed for their responsibility, however, there is usually a portion of the cost that falls to the patient’s responsibility.
                            <br /><br />
                            Our TMS coordinator will contact your insurance to get prior authorization for the procedure and determine your predicted out-of-pocket costs. As a general guide, when your insurance covers office visits with a copay, TMS is usually covered the same way, with a copay for each treatment session. However, if you have a deductible plan, your cost calculations are more complicated. You will usually need to meet your deductible first, then the cost will be at your co-insurance rate until you meet your out-of-pocket maximum. Again, this will all be thoroughly investigated by your TMS coordinator and discussed with you in detail. When certain requirements are met, discounts are also available.
                            <br /><br />
                            Redemption Psychiatry is in-network with most private insurance companies and would be willing to arrange a single-case agreement if we are not in-network. We are not Medicaid or Medicare providers.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I don’t have insurance?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            The cash rate for TMS varies, dependant on which treatment protocols are being utilized, but a full course of TMS treatment is generally several thousand dollars. The cost can be around 50% lower for the iTBS (or Intermittent Theta Burst) form of TMS, due to the decreased time needed to administer an equivalent treatment course. TBS is not yet approved by the FDA and does not qualify for insurance reimbursement. If you are unable to afford the treatment, you may be eligible for our discount and assistance programs.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What does TMS treatment feel like?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            During a typical 20-minute TMS treatment, the patients are reclined comfortably and carefully positioned with the treatment coil resting gently against the head in the treatment area. The patient will feel a rapid tapping sensation on the scalp and hear clicking sound. Plug-phones (noise-cancelling earplugs with built-in earphones) are provided to protect the patient’s hearing and allow the patient to listen to music or watch something on a screen positioned above the treatment chair. When treatment is over, the coil is removed from the patient’s head, the chair is inclined, and the patient is able to drive them selves’ home.
                            <br /><br />
                            <h3>The First Treatment</h3>
                            You will be taken to a treatment room, review and sign the treatment consent form, rate your symptoms using a standardized rating scale, and allowed to sit comfortably in a reclining chair. The technician will take several measurements of your head and perform calculations to map out the treatment location on your head with the highest possible precision. The doctor will then determine the amount of magnetic energy needed for your treatment by gradually stimulating the area of the brain that controls voluntary motor movement until it causes your hand to twitch. This is known as the motor threshold, which is used in determining the right TMS prescription for you. Throughout treatment, the amount of stimulation will be gradually increased to optimized response. You will then be given earplugs to wear during the procedure and the first treatment will be started at a low energy. The doctor will then make any necessary adjustments to optimize comfort and efficacy.
                            <br /><br />
                            <h3>Subsequent Treatments</h3>
                            Once the doctor enters your TMS prescription, the technician will administer the subsequent treatments following the prescription parameters. During each treatment, you will sit in a comfortable chair, wearing plug-phones, and the technician will use the laser guided system to precisely position the magnetic coil against your head. When the treatment starts, you will feel a rapid tapping sensation at 10 pulses per second for 4 second followed by an 11 second pause for about 18 minutes. You will remain awake and alert throughout the procedure and can watch and/or listen to pleasant media to pass the time and distract from any possible scalp discomfort. The technician may make minor adjustments to the coil position using the doctor’s prescription parameters to improve comfort without negatively impacting efficacy. You will be able to return to your normal daily activities after each treatment including driving and working with no restrictions.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can TMS be used with medication and therapy?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes. TMS can be used in conjunction with medication and therapy and is an effective complementary treatment. In fact, it is recommended for a patient to remain stable on any medication regimen they may be on throughout the course of treatment. If any medications changes occur, it is best to let your TMS technician know, so that any necessary changes to your TMS prescription can be made. It is also encouraged for patients to remain in or begin psychotherapy, as this is a critical part of the overall treatment plan for a depressed patient.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Is there anything I should avoid during the treatment duration?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Patients are asked to abstain from alcohol and any illicit drug use during their TMS treatment. It is also recommended to avoid increased amounts of caffeine.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>

                            How soon will I start feeling the effects of TMS?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Most patients start experiencing relief after 5-10 sessions, but this can vary greatly depending on the severity of Depression and the individual’s unique genetic makeup. Some may not feel the effects of TMS until as many as 30 sessions while some may feel the effect almost immediately after their first.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>

                            How long will the effects of TMS last?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            The duration of time which a patient will fill the benefits of TMS is also highly dependent on the patient and their induvial anatomy. Some patients will experience relief for a lifetime, some for a year or two, and some for several months. Depending on results, maintenance therapy, or booster sessions, may be recommended by a TMS physician.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Should anyone not receive TMS?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            It is not generally recommended for patients who have a history of seizures to receive TMS.  Those who have a metal plate in their head, or any other metal in and around their head should not have the procedure done (braces and fillings will not interfere with the treatment). TMS is also not recommended for patients with a history of diagnosed mania or personality disorders. If any of the above apply to you, please discuss with your psychiatrist to see if TMS is right for you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





