import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { SizeMe } from "react-sizeme"

export default function PdfViewer(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { pdf } = props;

  return (
    <SizeMe>
    {({ size }) => (
      <Document
      file={pdf}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width ? size.width : 1} />
      ))}
    </Document>
    )}
  </SizeMe>
  );
}

