import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiClock,} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--3" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient2">All You Need To Know About Ketamine Infusion Therapy</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />July, 2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>Ketamine was initially approved by the FDA as an anesthetic because of its analgesic
                                        properties and the ability to maintain cardiovascular stability. Earlier this century,
                                        researchers explored the <a href="https://www.biologicalpsychiatryjournal.com/article/S0006-3223(99)00230-9/fulltext" alt="source link">antidepressant effects of ketamine</a> and its efficacy as an off-label
                                        treatment for major depressive disorder (MDD) and suicidal thoughts.</p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-01.png" alt="Blog Images" />
                                        </div>
                                        <p className="mt--40">Tragically, <a href="https://www.who.int/news-room/fact-sheets/detail/depression" alt="source link">millions of people worldwide suffer</a> from severe and chronic depression – with resistance to antidepressants. <a href="https://ajp.psychiatryonline.org/doi/full/10.1176/appi.ajp.2013.13030392?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr_dat=cr_pub%3Dpubmed&" alt="source link">Preliminary evidence</a> suggests that some of these patients are likely to respond to ketamine infusion therapy. The robust and rapid action of ketamine provides a glimmer of hope in helping suicide prevention.</p>
                                        <p>In this blog, we’ve put together a guide to offer information about this new, potentially life-saving treatment.</p>
                                        <blockquote className="rn-blog-quote">The findings showed that those who received ketamine experienced significant symptomatic improvement within 110 minutes, and 35% maintained the response for a week. </blockquote>
                                        <h3>Ketamine Treatment for Depression</h3>
                                        <p>Ketamine is administered in clinical doses and incorporated used for various psychiatric disorders like bipolar disorder, MDD, PTSD, and chronic pain management. Findings from several placebo-controlled studies revealed that ketamine is effective for treatment-resistant major depressive disorder.</p>
                                        <p>In <a style={{color: "inherit"}} href="https://pubmed.ncbi.nlm.nih.gov/16894061/" alt="source link">a 2006 study</a>, independent groups of patients with treatment-resistant MDD were compared with those who received a placebo. The findings showed that those who received ketamine experienced significant symptomatic improvement within 110 minutes, and 35% maintained the response for a week.</p>
                                        <p><a href="https://www.psychiatryadvisor.com/home/depression-advisorketamine-changes-course-of-major-depressive-disorder-treatment/" alt="source link">Researchers are still exploring</a> the role of ketamine in mental health treatments.</p>
                                        <h3>What To Expect From Your Ketamine Infusion Appointment</h3>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100"  style={{boxShadow: "none"}} src="/assets/images/blog/blog-single-01.png" alt="BLog Images" />
                                            </div>
                                            <div className="content">
                                                <br /><br /><br />
                                                <p>Keep in mind that ketamine infusion treatments are tailored to suit each patient’s unique needs. </p>
                                                <h4 className="title">A Typical Infusion</h4>
                                                <ul className="list-style">
                                                    <li>First, your psychiatric healthcare provider will determine if ketamine is the right treatment for you.</li>
                                                    <li>The IV rate drip and the ketamine dosage will depend on your medical conditions and treatment responses.</li>
                                                    <li>Your medical team will inform you and your family about the risks and benefits of the procedure. </li>
                                                    <li>Then, you’ll be taken to a private room where <a href="https://rpsych.com/ketamine" alt="source link">specialist nurses will administer the dose</a> and keep a close eye on possible side effects. </li>
                                                    <li>Generally, the infusion takes 40-60 minutes and an additional 20-30 minutes to recover.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <p className="mt--25 mt_sm--5">As one of the largest private psychiatric practices in Arizona, <a href="https://rpsych.com/ketamine" alt="source link">our doctors have experience</a> in treating patients from all demographics with different combinations of medical disorders. Learn more about how we make ketamine as affordable as possible for our patients at Redemption Psychiatry.</p>
                                        <p className="mb--0">Visit one of our four clinics throughout the valley, or call us at <a href="tel:480-542-1212">(480) 542-1212</a> for an appointment. You deserve to live a happier and better life!</p>
                                        <br /><br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;