import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiClock,} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--3" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient2">Ketamine Treatment: <span style={{fontStyle: "italic"}}>Is It The Future Of Depression Treatment?</span></h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />July, 2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>As one of the <a href="https://www.who.int/news-room/fact-sheets/detail/depression" alt="source link">leading causes of disability</a>, depression affects nearly 264 million people globally and nearly 17 million adults in the U.S. alone. <a href="https://ajp.psychiatryonline.org/doi/full/10.1176/appi.ajp.163.1.28?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%3dpubmed" alt="source link">30% of people with major depressive</a> disorder (MDD) do not recover despite trying various structurally distinct antidepressants.</p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-02.png" alt="Blog Images" />
                                        </div>
                                        <br /><br />
                                        <h3>Could Ketamine Be The Answer? What Does The Research Say?</h3>
                                        <p className="mt--40">Ketamine, a glutamate N-methyl-D-aspartate (NMDA) receptor antagonist, was approved by the FDA as an anesthetic in 1970. However, its antidepressant effects were explored several years later because of the stigma around its recreational use in the 1970s.</p>
                                        <p>Ketamine has recently earned the reputation as an effective off-label treatment for MDD and suicidal thoughts. Researchers found that ketamine had fast-acting, strong, and long-term effects on chronic depression. The antidepressant effects of ketamine started to show within 4 hours of the placebo-controlled randomized trial, peaked at 72 hours, and the results lasted for about 1 to 2 weeks after. </p>
                                        <p>Other studies revealed the rapid yet transient anti-suicidal and <a href="https://www.biologicalpsychiatryjournal.com/article/S0006-3223(99)00230-9/fulltext" alt="source link">antidepressant effects of Ketamine</a> in patients with treatment-resistant MDD. Further showing that it may have prolonged effects with repeated administration. </p>
                                        <p>An <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6717708/" alt="source link">early study</a> evaluating the efficacy of repeated ketamine infusions discovered reduced depressive symptoms in responders after once-weekly ketamine infusions. </p>
                                        <p>Further studies show that ketamine-treated patients compared with midazolam-treated patients “showed greater improvement in depressive symptoms.” Similarly, another research indicated that ketamine infusion therapy was effective in reducing the frequency of suicidal ideation. </p>
                                        <blockquote className="rn-blog-quote">Ketamine produces robust and rapid effects within a few days of administration to reduce fatigue, suicidal ideation, and improve sleep patterns in patients with MDD.</blockquote>
                                        <h3>In conclusion</h3>
                                        <p>While monoaminergic-based depression treatments are effective for many patients, a significant subset does not respond to these agents. Compared with ketamine, these treatments show delayed onset, which poses increased suicidal and organ failure risks.</p>
                                        <p>This is where ketamine infusion therapy comes in. Ketamine produces robust and rapid effects within a few days of administration to reduce fatigue, suicidal ideation, and improve sleep patterns in patients with MDD.</p>
                                        <p>According to <a href="https://www.sciencedirect.com/science/article/pii/S1359644619302831?via%3Dihub" alt="source link">Drug Discovery Today</a>, researchers are still exploring ketamine’s possible role in mental health treatments. The drug’s mechanism has generated much interest in its efficacy as a treatment for severe depression.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail" style={{textAlign: "center"}}>
                                                <img className="w-75" src="/assets/images/blog/blog-single-02.jpg" alt="BLog Images" />
                                            </div>
                                            <div className="content">
                                                <br /><br /><br />
                                                <h4 className="title">How Ketamine Works</h4>
                                                <ul className="list-style">
                                                    <li>Your psychiatric care provider determines if ketamine is appropriate for you. </li>
                                                    <li>Ketamine is available in topical, oral, and IV forms. </li>
                                                    <li>Plasma concentrations are shown to reach maximum levels minutes after the IV infusion. </li>
                                                    <li>The treatment typically involves an initial round of six infusions and subsequent infusions until long-term remission is sustained.</li>
                                                    <li>The IV rate drip and ketamine doses are different for every patient based on their medical conditions and response. </li>
                                                    <li>Generally, the infusion takes 40-60 minutes and additional 20-30 minutes to recover.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <h3>Get Back To Living Your Best Life Today!</h3>
                                        <p className="mt--25 mt_sm--5"><a href="https://rpsych.com/ketamine" alt="source link">Redemption Psychiatry</a> is one of the largest private psychiatric practices in Arizona. Their doctors are specialized in psychiatry and treatment-resistant disorders. For those looking for affordable ketamine infusion treatment for depression in Arizona can get in touch with their expert physicians for a consultation to <a href="https://rpsych.com/ketamine" alt="source link">determine if this is the right treatment for them.</a></p>
                                        <p className="mb--0"> Redemption Psychiatry has four clinics throughout the valley that are equipped to administer ketamine. Call for appointments at <a href="tel:480-542-1212">(480) 542-1212</a>.</p>
                                        <br /><br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;