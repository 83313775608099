import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiClock,} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--3" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient2">Battling the Stigma around Mental Health Treatment</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />July, 2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>A staggering <a href="https://www.who.int/news-room/fact-sheets/detail/depression" alt="source link">264 million people worldwide suffer</a> from mental health disorders, placing mental illness as the leading cause of disability. Despite the prevalence of these orders and treatment availability, 66% of all patients do not seek help due to the discrimination, stigma, and neglect surrounding mental health treatment.
</p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-04.jpg" alt="Blog Images" />
                                        </div>
                                        <br /><br />
                                        <p className="mt--40">People living with mental illnesses are burdened with prejudice and stereotypes of stigmatization. Their symptoms are often called a “phase,” or they are told to “snap out of it.” Often they are judged feared, avoided, or perceived as a threat to instill shame, fear, and embarrassment.</p>
                                        <p>For people already struggling with the burden of an invisible illness, stigma is an unbearable addition to their pain. The unwieldy power of stigma interferes with their decision to reach out to mental health professionals and often leads to alienation.</p>
                                        <h3>Mental Health As An Integral Part Of Health And Well-Being</h3>
                                        <p>The World Health Organization started a <a href="https://www.who.int/mental_health/action_plan_2013/bw_version.pdf?ua=1" alt="source link">global fight against mental health discrimination</a> and stigma at the beginning of the 1990s. They have since highlighted that health is a state of complete mental, physical, and social well-being, rather than the absence of physical disease. </p>
                                        <h3>How To Fight Stigma And Support Mental Health</h3>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail" style={{textAlign: "center"}}>
                                                <img className="w-100" src="/assets/images/blog/blog-single-03.png" alt="BLog Images" />
                                            </div>
                                            <div className="content">
                                                <br /><br /><br />
                                                <ul className="list-style">
                                                    <li><h4>Talk Openly About Your Mental Health Journey</h4><br /><p>Sharing your story is empowering for you and others struggling with their mental illnesses. Disregard harmful stereotypes and encourage others to speak up and seek the help of mental health professionals.</p></li>
                                                    <li><h4>Educate Yourself And Others</h4><br /><p>Educating yourself about mental health struggles helps put a name to your illness, and show empathy, support, and compassion when speaking to others.</p></li>
                                                    <li><h4>Encourage Equality Between Mental And Physical Illness</h4><br /><p>We need to recognize mental health as essential to physical health, rather mutually-exclusive to it if we hope to beat the stigma.</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <h3>Stigma Leads To Delayed Treatment</h3>
                                        <p className="mt--25 mt_sm--5">Although treatments are known for mental disorders, stigma is one of the biggest barriers to accessing mental healthcare.</p>
                                        <p className="mt--25 mt_sm--5">Out of 17 million adults in the U.S. who struggle with depression, about 35% still do not seek aid. Additionally,  despite using structurally distinct antidepressants, 30% of people with Major Depressive Disorder (MDD) remain depressed.</p>
                                        <h3>Ketamine Treatment For Depression</h3>
                                        <p className="mt--25 mt_sm--5"><a href="https://ajp.psychiatryonline.org/doi/full/10.1176/appi.ajp.2013.13030392?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%3dpubmed" alt="source link">Preliminary evidence </a>suggests that Ketamine infusion therapy is an effective alternative for patients who do not respond to standard depression treatments. Ketamine has earned the reputation as an effective off-label treatment for MDD and suicidal thoughts.  </p>
                                        <p className="mt--25 mt_sm--5">Many <a href="https://www.psychiatryadvisor.com/home/depression-advisor/ketamine-changes-course-of-major-depressive-disorder-treatment/" alt="source link">randomized placebo-controlled trials</a> have found ketamine to reduce depressive symptoms in patients at a faster rate. It is also known to lower suicidal ideation, potentially helping with suicide prevention—the second leading cause of death in 15-29-year-olds</p>
                                        <p className="mt--25 mt_sm--5">The specialized doctors at <a href="https://rpsych.com/ketamine" alt="source link">Redemption Psychiatry</a> can determine <a href="https://rpsych.com/ketamine" alt="source link">if Ketamine infusion treatment is right for you</a> or your loved one. As one of the largest private psychiatric practices in Arizona, they specialize in treatment-resistant disorders and advanced psychiatric treatments.</p>
                                        <p className="mb--0">You can visit one of their <a href="https://rpsych.com/contact" alt="source link">four ketamine clinics throughout the valley.</a> Call for appointments at <a href="tel:480-542-1212">(480) 542-1212</a>.</p>
                                        <br /><br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;