import React from 'react'
const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span>READY TO GIVE US A CALL?</span>
                            <h2><a style={{color: "white"}} href="tel:480-576-8666" alt="phone number">(480) 576-8666</a></h2>
                            <a className="rn-button-style--2" href="/contact"><span>Send us a message</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;