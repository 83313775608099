import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Redemption Psychiatry TMS </title>
                    <meta name="keywords" content="TMS, transcranial magnetic stimulation, Ketamine, Infusions, MDD, Anxiety, Depression, PTSD, OCD, ADD, ADD/ADHD, ADHD, Ketamine Assisted Therapy, Psychiatrist, Psychiatry, Medical Care, Psych, Medication, Healthcare, Mental Health, Treatment, Child Health, Adolescent Health, Gilbert, Chandler, Phoenix, Mesa, Arizona" />
                    <meta property="og:title" content={this.props.pageTitle + " || Redemption Psychiatry TMS"}></meta>
                    <meta property="og:description" content="An exciting and proven highly effective non-medication approach to depression and other conditions. Get back to living your best life. Quality, safe and effective TMS Treatments in Arizona by Redemption Psychiatry." data-rh="true"></meta>
                    <meta property="og:url" content="https://www.rpsychtms.com/"></meta>
                    <meta property="og:site_name" content="Redemption Psychiatry TMS || Arizona" data-rh="true"></meta>
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" data-rh="true"></meta>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
