

let data2 = [
    {
        images: '05',
        title: 'Stefanie Reinsch',
        designation: 'Gilbert',
        socialNetwork: []
    },
    {
        images: '06',
        title: 'Bianca Castillo',
        designation: 'Phoenix',
        socialNetwork: []
    },
];

export default data2;