import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";



class Disclaimer extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='PrivacyPolicy' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--12" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title color-1">Disclaimer</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Resource Area */}
                <div className="service-area pt--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>DISCLAIMER:</h2>
                                    <p>It is important to fully educate yourself before pursuing any medical treatment. Deciding to undergo TMS therapy is no different, and we hope this information will be helpful in better understanding the treatment. Please note that the information contained on this webpage is not intended as an advertisement for treatment. You must be prescribed TMS treatment by a mental health specialist. Like all medical treatments, we cannot guarantee results or promise there will not be any adverse effects. Although many patients experience total relief from TMS treatments, it is possible for patients to have no response from treatment. Each individual’s response is unique and dependent on several factors, so you should know that your response may not be typical and should not be compared with the experiences of others or research outcomes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br /><br /><br /><br />
                {/* End Resource Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Disclaimer;