import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const TestimonialOne = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Tabs>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p style={{fontSize:"25px"}}>I think I’ve tried everything out there, but either side effects or it doesn’t help. People would call me bashful, but I was just depressed. For over 20 years. I just didn’t talk. When I would go to work I would do my job, and I would go home. And then I would just sit there, until the next day. And now I feel more outgoing, I’m doing more with my life, and I’m happier. TMS has been life changing for me</p>
                            </div>
                            <div className="author-info">
                                <h6><span> - Cathy S.</span></h6>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p style={{fontSize:"25px"}}>I suffered from depression for years. I lost my job, dropped out of school, and could barely get out of bed. Seroquel, Trintellix, Rexulti, I tried probably 8 medications and I saw very minimal effects from all of them. After experiencing TMS, I saw better quality of life and just joy. Something I haven’t felt in years. Just pure joy.</p>
                            </div>
                            <div className="author-info">
                                <h6><span> - Andrew R.</span></h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p  style={{fontSize:"20px"}}>TMS changed my life. I was having the worst depressive episode of my life that lasted for 2 years. Medications didn’t help, I was barely able to work part-time, and had zero appetite. My mom heard about TMS on the radio and told me about it, I scoffed thinking it sounded ridiculous and scary. I interrogated the provider at my intake appointment, afraid it would cause brain damage or cancer – it doesn’t. I was an early responder! I started to feel better around week 3, it felt like I was a teenager again and like anything was possible! I got my motivation back, my desire to look toward the future, and the ability to stop limiting myself because I was too sick to live my life! Now I have a career, I moved out of my parent’s house, I have my own home and the cutest doggo. I still have ups and downs, which is normal, but I feel so much better now than I did before. TMS truly is a life changer!</p>
                            </div>
                            <div className="author-info">
                                <h6><span> - Anonymous Patient</span></h6>
                            </div>
                        </div>
                    </TabPanel>
                    
                    <TabList className="testimonial-thumb-wrapper">
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-1.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-2.png" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-3.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                    </TabList>
                </Tabs>
            </div>
        </div>
    )
}

export default TestimonialOne
