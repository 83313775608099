import React from 'react'
// import TeamTwo from "./team/TeamTwo";
import TeamThree from "./team/TeamThree";
import BrandTwo from "../elements/BrandTwo";

import PageHelmet from "../component/common/Helmet";
// import Breadcrumb2 from "../elements/common/Breadcrumb2";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const Team = () => {
    return (
        <div>
            <PageHelmet pageTitle='Team' />
            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--2" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Meet Our Team</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--120 bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>TMS Coordinators</h2>
                                    </div>
                                </div>
                            </div>
                            <TeamThree column="col-lg-3" teamStyle="team-style--bottom" item="3" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--40 bg_color--5">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2><a href="https://rpsych-main-hhv66.ondigitalocean.app/provider-biographies">Click here to meet our providers</a></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}


                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}


            </main>
            {/* End Page Wrapper  */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </div>

    )
}

export default Team





