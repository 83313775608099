import React, { Component } from "react";
import { Button, Modal } from 'react-bootstrap'
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp, FiCloud, FiCloudDrizzle } from "react-icons/fi";
import { GiLoveInjection } from "react-icons/gi";
import { AiOutlineShake } from "react-icons/ai";
import { MdTimeline } from "react-icons/md";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PdfViewer from "../component/common/PdfViewer";
import Anxiety from "../../public/assets/pdfs/Anxiety.pdf";
import CloudTMSbrochure from "../../public/assets/pdfs/CloudTMSbrochure.pdf";
import Depression from "../../public/assets/pdfs/Depression.pdf";
import Ketamine from "../../public/assets/pdfs/Ketamine.pdf";
import TMSTimeLine from "../../public/assets/pdfs/TMSTimeLine.pdf";


const ResourcesList = [
    {
        icon: <FiCloud/>,
        title: 'Cloud TMS',
        description: 'Learn more from our equipment manufacturer, Cloud TMS.',
        pdf: CloudTMSbrochure
    },
    {
        icon: <MdTimeline />,
        title: 'TMS Time Line handout',
        description: 'You’re interested in TMS, now what?',
        pdf: TMSTimeLine
    },
    {
        icon: <FiCloudDrizzle />,
        title: 'Depression Handout',
        description: 'Learn more about Depression',
        pdf: Depression
    },
    {
        icon: <AiOutlineShake />,
        title: 'Anxiety Handout',
        description: 'Learn more about Anxiety',
        pdf: Anxiety
    },
    {
        icon: <GiLoveInjection />,
        title: 'Ketamine Handout',
        description: 'Learn more about Ketamine Infusion Therapy',
        pdf: Ketamine
    },
]
const LinksList = [
    {
        icon: <FiCloud />,
        title: 'Cloud TMS',
        description: 'Visit Cloud TMS website',
        link: 'https://mycloudtms.com/'
    },
    {
        icon: <GiLoveInjection />,
        title: 'Ketamine Therapy',
        description: 'Visit our Ketamine Infusion Therapy website',
        link: 'https://rpsych.com/ketamine'
    },
]
class Resources extends Component {
    constructor() {
        super();
        this.state = {
            activeModal: null,
        }
    }

    clickHandler(e, i) {
        this.setState({ activeModal: i })
    }
    hideModal() {
        this.setState({ activeModal: null })
    }
    render() {
        this.clickHandler = this.clickHandler.bind(this);
        this.hideModal = this.hideModal.bind(this);
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Resources' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--9" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title color-1">Resources</h2>
                                    <p>A collection of useful links for patients wanting to learn more</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Links Area */}
                <div className="service-area pt--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Additional Website Links</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper" style={{ justifyContent: "center" }}>
                            {LinksList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.link} >
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Start Links Area */}

                {/* Start Resource Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>PDF Handouts</h2>
                                    <p>Feel free to download and print or save for reference later!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper" style={{ justifyContent: "center" }}>
                            {ResourcesList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <button className="text-center" style={{ border: "0px", width: "30rem" }} id={i} onClick={e => this.clickHandler(e, i)}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </button>
                                    <Modal
                                        size="lg"
                                        id={i}
                                        show={this.state.activeModal === i}
                                        onHide={this.hideModal}
                                    >
                                        {/* <Modal.Header closeButton>
                                            <Modal.Title>{val.title}</Modal.Title>
                                        </Modal.Header> */}
                                        <Modal.Body id="contained-modal-title-vcenter" style={{ justifyContent: "center" }}>
                                            <div style={{ justifyContent: "center" }}>
                                                <PdfViewer style={{ width: "100%" }} pdf={val.pdf} />
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.hideModal}>
                                                Close
                                                </Button>
                                            <Button variant="primary" onClick={this.hideModal}>
                                                Download
                                                </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Start Resource Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Resources;